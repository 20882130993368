CW = window.CW || {}

CW_cityInitSearchEvent = function (val, obj) {
  CW.setCity(val)
}

CW.setCity = function (val) {
  $.ajax({
    url: '/ajax/geo/set/',
    type: 'post',
    dataType: 'JSON',
    data: {
      id: val
    },
    success: CW.successGeoCity
  })
}

CW.successGeoCity = function (res) {
  if (res.status == 'ok') {
    var city = res.payload.city
    $('input[name=ORDER_PROP_7]').val('')
    $('input[name=ORDER_PROP_39]').val('')

    // console.log($('input[name=ORDER_PROP_7]').val());
    // console.log($('input[name=ORDER_PROP_39]').val());
    try {
      CW.imlWidget.setAddress('Не выбран')
    } catch (e) {}

    $('.location-href span').text(city)
    $('.site-popup .close-button').trigger('click')

    if (CW.isOrderSPA) {
      CW.sendOrder()
    } else {
      location.reload()
    }
  } else if (res.status == 'error') {
    console.log(res.error)
  }
}

$(function () {
  $('.city-list a').on('click', function (e) {
    e.preventDefault()
    CW.setCity($(this).data()['cityId'])
  })
})

CW = window.CW || {};

CW.Cart = [];

CW.basket.regBasketUpdateEvent('productCard', function (res) {
    CW.isOpt = res.IS_OPT;
    CW.isPersonal = res.IS_PERSONAL;
    CW.isBigOpt = false;

    let $productSize  =  $('.product-size-block');
    let $infoProduct  =  $('.info-product-wr');
    let $b2cInfoProduct = $('.b2c-actions-row.info-product-wr');
    let infoProductBlocks = document.querySelectorAll('.info-product-wr');
    let $offerRow     = $productSize.find('.offer-wr');

    //подгружаем ajax-сом систему скидок
    let $totalBlock        =  $productSize.find('.total-sum-block');
    let $sumInfo           =  $infoProduct.find('.sale-sum');
    let $discValueInfo     =  $infoProduct.find('.next-discount-value');
    let $sumProd           =  $totalBlock.find('.bonus');
    let $discValueProd     =  $totalBlock.find('.percent');
    let $wrPrice           =  $infoProduct.find('.price-wr');
    let $wrCurrentPrice    =  $infoProduct.find('.current-price-wr');
    let $userPrice         =  $infoProduct.find('.current-price .price');
    let $priceMessage      =  $infoProduct.find('.price-message');
    // let $favorites         =  $infoProduct.find('.add-to-favorites');

    let favorites =  [];
    infoProductBlocks.forEach(item => {
        const favoriteElem = item.querySelector('.add-to-favorites')
        favorites.push(favoriteElem);
    })

    //закрываем попап размеров
    $productSize.removeClass('open');
    $('body').removeClass('fixed-body');
    $('body').removeClass('prevent-scroll');
    var userPriceName = "";


    if (res.PROGRESS_DISCOUNT && !$userPrice.data('is-sale')) {
        let nextDiscountPrice     =  res.PROGRESS_DISCOUNT.nextDiscountPrice;
        let nextDiscountPercent   =  res.PROGRESS_DISCOUNT.nextDiscountPercent;
        let percentDiscount       =  res.PROGRESS_DISCOUNT.currentDiscountPercent;

        // percentDiscount *= 100;
        if(CW.maxPercentDiscount){
            if (percentDiscount > CW.maxPercentDiscount) {
                percentDiscount = CW.maxPercentDiscount;
            }
        }

        if (!percentDiscount) {
            if ($userPrice.data('coeff')) {
                percentDiscount = 100 - ($userPrice.data('coeff') * 100);
            }
        }

        if ($userPrice.data('is-personal')) {
            CW.isPersonal = $userPrice.data('is-personal')
        }

        let coeff = (100 - percentDiscount) / 100;
        //если товар из распродажи
        if(CW.isSale || CW.disableUpdateCoeffPrice){
            coeff = 1;
        }

        CW.coeffPrice = coeff;

        if (CW.isOpt || CW.isPersonal) {
            if (nextDiscountPrice) {
                $sumInfo.add($sumProd).text(CW.price(nextDiscountPrice, true));
            }
            if (nextDiscountPercent) {
                $discValueInfo.text('До скидки ' + nextDiscountPercent + '% — ');
                $discValueProd.text('До скидки ' + nextDiscountPercent + '% — ');
            }

            userPriceName = "оптовая";
            if(percentDiscount > 0 && !CW.isPersonal){
                userPriceName += " + скидка "+percentDiscount+"%";
            }
            if(CW.isOpt && percentDiscount >= 8){
                userPriceName = "крупный опт";
                CW.isBigOpt = true;
                $discValueInfo.text("");
                $discValueProd.text("");
                $sumInfo.add($sumProd).text("");

                // если никакой текст не выведен - удаляем ближайший элемент с подсказкой, если он есть
                // первый вариант блока-родителя тултипа
                const closestTooltip = $discValueInfo.closest('.next-discount-text') ? $discValueInfo.closest('.next-discount-text').find('.tooltip-help-block') : null;

                // второй вариант блока-родителя тултипа
                const closestTooltip2 = $discValueProd.closest('.left-before-recalculation') ? $discValueProd.closest('.left-before-recalculation').find('.tooltip-help-block') : null;

                // третий
                const closestTooltip3 = $sumProd.closest('.discount-info') ? $sumProd.closest('.discount-info').find('.tooltip-help-block') : null;

                if(closestTooltip || closestTooltip2 || closestTooltip3) {
                    closestTooltip ? closestTooltip.css('display', 'none') : null;
                    closestTooltip2 ? closestTooltip2.css('display', 'none') : null;
                    closestTooltip3 ? closestTooltip3.css('display', 'none') : null;
                }
            }
            if(CW.isPersonal){
                userPriceName = "персональная скидка "+percentDiscount+"%";
            }
            var userOptPrice = $wrPrice.data('opt') * coeff;
            $userPrice.text(CW.price(userOptPrice,true));
            $('.bonus-add').text(CW.bonusPrint(parseInt(userOptPrice * 0.01), true));

            $priceMessage.text(userPriceName);
            $priceMessage.show();

            $wrCurrentPrice.find('.old-price').addClass('open');
            $wrPrice.find('.price-block').each(function(index) {
                var _this = $(this);
                _this.removeClass('my-price');
                if(CW.isBigOpt && _this.hasClass('bigOpt')){
                    _this.addClass('my-price');
                }else if(!CW.isBigOpt && _this.hasClass('opt') && !CW.isPersonal){
                    _this.addClass('my-price');
                }

                //показываем цены если цена зависит от бренда
                if(CW.brandPriceIsHidden){
                    if(_this.hasClass('bigOpt') || _this.hasClass('opt')){
                        _this.find('.price').show();
                        _this.find('.hidden-price').hide();
                    }
                }
            });
        } else {
            if (nextDiscountPrice) {
                $sumInfo.add($sumProd).text(CW.price(nextDiscountPrice, true));
                $discValueInfo.text('до пересчета заказа по оптовой цене');

                if(window.innerWidth < 500) {
                    $discValueProd.text('до оптовой цены');
                } else {
                    $discValueProd.text('до пересчета заказа по оптовой цене');
                }

            }

            var userRoznPrice = $wrPrice.data('rozn') * coeff;
            $userPrice.text(CW.price(userRoznPrice,true));
            $('.bonus-add').text(CW.bonusPrint(parseInt(userRoznPrice * 0.05),true));

            $priceMessage.hide();
            $wrCurrentPrice.find('.old-price').removeClass('open');
            $wrPrice.find('.price-block').each(function(index) {
                var _this = $(this);
                _this.removeClass('my-price');
                if(_this.hasClass('rozn')){
                    _this.addClass('my-price');
                }

                //скрываем цены если цена зависит от бренда
                if(CW.brandPriceIsHidden){
                    if(_this.hasClass('bigOpt') || _this.hasClass('opt')){
                        _this.find('.price').hide();
                        _this.find('.hidden-price').show();
                    }
                }
            });
        }
    }

    var basketProduct = [];

    res.PRODUCTS.forEach(function (one) {
        basketProduct[one.PRODUCT_ID] = one.QUANTITY;
    });

    let allSelectSize = '';

    $offerRow.each(function (index, element) {
        var _this = $(this);
        var id = Number(_this.data('id'));
        let $inputSize = _this.find('.amount-input');

        if (!basketProduct[id]) {
            // $inputSize.val(0);
            // $inputSize.prop('disabled', false);
            // _this.find('.minus').add( _this.find('.plus')).show();
            return;
        }

        let nameSize = _this.find('.size-value').text();
        allSelectSize += nameSize + ', ';

        $inputSize.val(Number(basketProduct[id]));
        // $inputSize.prop('disabled', true);
        // _this.find('.minus').add( _this.find('.plus')).hide();
    });

    if(allSelectSize.length > 0){
        $infoProduct.find('.btn-select-size input').val(allSelectSize.slice(0, -2));
        $infoProduct.find('.btn:not(.btn-select-size)').addClass('red-btn in-basket');
    }

    //для базового продукта
    var btn = $infoProduct.find('.btn');
    if(!btn.data("have-offers")){
        var $amountChooseProduct = $infoProduct.find('.choose-product');
        var $amountBaseProduct = $amountChooseProduct.find('.amount-input');
        var productId = btn.data("product-id");
        if(basketProduct[productId]){
            // btn.addClass('red-btn in-basket');
            btn.addClass('red-btn');
            $amountBaseProduct.val(Number(basketProduct[productId]));
            // $amountBaseProduct.prop('disabled', true);
            // $amountChooseProduct.find('.minus').add($amountChooseProduct.find('.plus')).hide();
        }else{
            // btn.removeClass('red-btn in-basket');
            btn.removeClass('red-btn');
            //$amountBaseProduct.val(1); ламаеся логика из-за мультовости товара
            // $amountBaseProduct.prop('disabled', false);
            // $amountChooseProduct.find('.minus').add($amountChooseProduct.find('.plus')).show();
        }
    }


    // если в b2c виде выбрать размер, который уже был добавлен в корзину ранее, в инпуте количества проставляется добавленное в корзину количество
    $('.js-select-size-content').on('click', function(e){
        if(e.target.closest('.js-size-row')) {
            var productId = e.target.closest('.js-size-row') ? e.target.closest('.js-size-row').dataset.id : null;

            if(!productId) return;

            var mult = e.target.closest('.js-size-row') ? e.target.closest('.js-size-row').dataset.multy : 1;
            var $amountB2CProduct = $b2cInfoProduct.find('.amount-input');

            if(basketProduct[productId]){
                $amountB2CProduct.val(Number(basketProduct[productId]));
            }else{
                $amountB2CProduct.val(mult);
            }
        }
    })


    // favorites - массив всех элементов с кнопкой добавить в избранное на станице
    // alreadyInFavoritesArr - уже добавленные в избранное
    const alreadyInFavoritesArr = res.DELAY_PRODUCT;

    // проверяем, какие из товаров на странице, добавлены в избранное
    if(favorites.length) {
        favorites.forEach(item => {
            const favoritesId = item ? Number(item.dataset.productId) : null;

            if(favoritesId && alreadyInFavoritesArr.includes(favoritesId)) {
                item.classList.add('in-favorites');
            } else if (favoritesId) {
                item.classList.remove('in-favorites');
            }
        })
    }

});

$(function () {

    $('.price-wr').each(function(){
        const prices = this.querySelectorAll('.price-block');
        if(prices.length >=3) {
            prices[0].classList.add('price-opt');
        }
    })
});


$(function () {

    document.addEventListener('click', function(e){
        const target = e.target;

        // проверяем, что страница каталога
        if(!document.querySelector('.catalog-page')) return

        // кнопка добавить в избранное в табличном виде каталога
        if(target.closest('.add-to-favorites')) {
            addToFavoritesFromCatalog(target);
        }
        //добавить в корзину
        if(target.closest('.add-to-cart')) {
            addToCartFromCatalog(target);
        }

        if(target.closest('.icon-mark')) {
            changeQuantity(target);
        }
    }, false);


    //добавить в избранное
    function addToFavoritesFromCatalog(elem) {
        var _this = $(elem.closest('.add-to-favorites'));
        var productId = _this.data("product-id");
        var action = 'add';

        if(_this.hasClass('in-favorites')){
            action = 'remove';
        }
        $.ajax({
            url: '/ajax/favorites/',
            global: false,
            type: "POST",
            data: ({
                action: action,
                id: productId
            }),
            success: function (res) {
                if(res.result && res.result == 'fail'){
                    console.log(res.error);
                }else{
                    CW.basket.setBasket(res);
                }
            }
        });
    }


    //Добавить в избранное для каталога в виде плитки карточек (сетки)
    $('.wr-product .product-mesh .add-to-favorites').on('click', function () {
        var _this = $(this);
        var productId = _this.data("product-id");
        var action = 'add';

        if(_this.hasClass('in-favorites')){
            action = 'remove';
        }
        $.ajax({
            url: '/ajax/favorites/',
            global: false,
            type: "POST",
            data: ({
                action: action,
                id: productId
            }),
            success: function (res) {
                if(res.result && res.result == 'fail'){
                    console.log(res.error);
                }else{
                    CW.basket.setBasket(res);
                }
            }
        });
    });

});

import Swiper from '../libs/swiper-bundle.min';

export default class Slider {
    constructor({selector}) {
        if (!selector) return

        this.slider = selector
        this.sliderInstance = this.getSlider()
    }

    getSlider() {
        this.btnPrev = this.slider.querySelector('.js-slider-prev')
        this.btnNext = this.slider.querySelector('.js-slider-next')

        let breakpoints = {}
        let loop = true
        let pagination = {}
        let autoplay = false
        let watchSlidesProgress = false
        let thumbSlider = '';

        let navigation = {
            prevEl: this.btnPrev || null,
            nextEl: this.btnNext || null
        }

        let callback = function(){}
        let callback2 = function(){}

        if (this.slider.classList.contains('js-popular-categories')) {
            breakpoints = {
                320: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                440: {
                    slidesPerView: 3.5,
                    spaceBetween: 15,
                },
                720: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1140: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                1330: {
                    slidesPerView: 5.5,
                    spaceBetween: 20,
                },
                1870: {
                    slidesPerView: 6.5,
                    spaceBetween: 20,
                },
                1920: {
                    slidesPerView: 8,
                    spaceBetween: 20,
                }
            }
        }

        else if (this.slider.classList.contains('js-special-offers')) {
            loop = false;

            breakpoints = {
                320: {
                    slidesPerView: 1.7,
                    spaceBetween: 8,
                },
                720: {
                    slidesPerView: 2.2,
                    spaceBetween: 10,
                },
                1000: {
                    slidesPerView: 2.6,
                    spaceBetween: 20,
                },
                1280: {
                    slidesPerView: 3.2,
                    spaceBetween: 20,
                },
                1600: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
            }
        }

        else if (this.slider.classList.contains('js-main-slider-desktop')) {
            pagination = {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }
            autoplay = {
                delay: 5000,
                //pauseOnMouseEnter: true
                disableOnInteraction: false,
            }

            /* в слайдере на главной добавляем класс активности для первого буллета для работы анимации */
            callback = function() {
                if(this.el) {
                    const bullets = this.el.querySelectorAll('.swiper-pagination-bullet');
                    if(bullets.length > 0) {
                        bullets[0].classList.remove('swiper-pagination-bullet-active');
                        setTimeout(() => {
                            bullets[0].classList.add('swiper-pagination-bullet-active');
                        }, 100)
                    }
                }
            }
        }

        else if (this.slider.classList.contains('js-mobile-main-slider')) {
            pagination = {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }
            autoplay = {
                delay: 5000,
                //pauseOnMouseEnter: true,
                disableOnInteraction: false,
            }

            /* в слайдере на главной добавляем класс активности для первого буллета для работы анимации */

            callback = function() {
                if(this.el) {
                    const pagination = this.el.querySelector('.js-mobile-main-slider .swiper-pagination');
                    const bullets = pagination.querySelectorAll('.swiper-pagination-bullet');
                    if(bullets.length > 0) {
                        bullets[0].classList.remove('swiper-pagination-bullet-active');
                        setTimeout(() => {
                            bullets[0].classList.add('swiper-pagination-bullet-active');
                        }, 100)
                    }
                }
            }
        }

        else if (this.slider.classList.contains('js-brands-slider')) {
            loop = false;

            breakpoints = {
                320: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                },
                720: {
                    slidesPerView: 3.5,
                    spaceBetween: 40,
                },
                1000: {
                    slidesPerView: 3.6,
                    spaceBetween: 50,
                },
                1270: {
                    slidesPerView: 5,
                    spaceBetween: 100,
                },
            }
        }

        else if (this.slider.classList.contains('js-optovikam-brands-slider')) {
            loop = false;

            breakpoints = {
                320: {
                    slidesPerView: 1.6,
                    spaceBetween: 8,
                },
                720: {
                    slidesPerView: 2.5,
                    spaceBetween: 8,
                },
                1000: {
                    slidesPerView: 4.6,
                    spaceBetween: 8,
                },
                1270: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
            }
        }

        else if (this.slider.classList.contains('js-product-gallery-slider')) {
            breakpoints = {
                320: {
                    slidesPerView: 2.2,
                    spaceBetween: 8,
                },
                720: {
                    slidesPerView: 2.3,
                    spaceBetween: 10,
                },
                1000: {
                    slidesPerView: 2.6,
                    spaceBetween: 10,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                1500: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
            }
        }

        else if (this.slider.classList.contains('js-news-slider')) {
            loop = false;

            breakpoints = {
                320: {
                    slidesPerView: 1.7,
                    spaceBetween: 8,
                },
                720: {
                    slidesPerView: 2.2,
                    spaceBetween: 10,
                },
                1000: {
                    slidesPerView: 2.6,
                    spaceBetween: 20,
                },
                1280: {
                    slidesPerView: 3.2,
                    spaceBetween: 20,
                },
                1600: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
            }
        }

        if (this.slider.classList.contains('js-actual-slider')) {
            loop = false;

            breakpoints = {
                320: {
                    slidesPerView: 2.2,
                    spaceBetween: 8,
                },
                720: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                780: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1500: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                },
            }
        }

        return new Swiper(this.slider.querySelector('.swiper'), {
            slidesPerView: this.slider.dataset.slidesPerView || 1,
            spaceBetween: this.slider.dataset.dataSpaceBetween || 20,
            loop: loop,
            navigation: navigation,
            breakpoints: breakpoints,
            pagination: pagination,
            autoplay: autoplay,
            watchSlidesProgress: watchSlidesProgress,
            thumbs: {
                swiper: thumbSlider,
            },
            on: {
                init: callback,
                setTranslate: callback2,
            }
        })
    }

}



import Swiper from './libs/swiper-bundle.min';

$(document).ready(function(){
    if(document.querySelector('.js-popular-categories')) {
        var indexPopularSections = new Swiper(".js-popular-categories", {
            slidesPerView: 3.5,
            spaceBetween: 10,

            breakpoints: {
                768: {
                    slidesPerView: 4.5,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 5.5,
                },
                1441: {
                    slidesPerView: 7.5,
                },
            },

            on: {
                init() {
                    if(document.querySelector('.popular-categories')) {
                        let intervalHandler;
                        this.el.addEventListener('mouseenter', (e) => {
                            let $this = this;
                            let xOffset = e.offsetX;
                            let widthWrap = document.querySelector('.popular-categories') ? document.querySelector('.popular-categories').offsetWidth : '';
                            let halfWidthWrap = widthWrap / 2;

                            intervalHandler = setInterval(function () {
                                if( xOffset < halfWidthWrap ) {
                                    if($this.progress >= 0) {
                                        $this.setTranslate($this.getTranslate() + 0.5);
                                    }
                                } else {
                                    if($this.progress <= 1) {
                                        $this.setTranslate($this.getTranslate() - 0.5);
                                    }

                                }
                            }, 10);
                        });


                        this.el.addEventListener('mouseleave', () => {
                            clearInterval(intervalHandler);
                        })
                    }

                }
            },
        });
    }

})


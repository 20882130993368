$(function () {
    //инициируем маску для телефона
    $('input[type=\'tel\']').on('focus', function () {
        $(this).mask('+7(999)999-99-99', {autoclear: false});
    });

    //кастомный placeholder при загпузке страницы
    $(document).ready(function(){
        let $inputs = $('.input-text-row input');

        $inputs.each(function (index, elem) {
            if ($(elem).val() != "") {
                $(elem).closest('.input-text-row').addClass('input-filled');
            }
        });
    });

    //кастомный placeholder для input text при заполнении
    $('.input-text-row input').on('change', function () {
        let $inputRow = $(this).parent('.input-text-row');

        if ($(this).val() == '') {
            $inputRow.removeClass('input-filled');
        } else {
            $inputRow.addClass('input-filled');
        }
    });

    //убираем ошибку при заполнении поля
    $('.input-text-row input').on('keydown', function () {
        $(this).parent().removeClass('error');
    });

    //проверка на value input text required и вывод ошибки
    $('body').on('click', '.btn-send-form', function (e) {
        let flag = true;
        let $form = $(this).closest('form');
        let $input = $form.find('.input-text-row input.required, .textarea-row textarea.required');

        $input.each(function () {
            let $this = $(this);
            if ($this.val() == '') {
                $this.parent().addClass('error');
                $this.siblings('.error-text').text('Поле не заполнено');
                flag = false;
            } else {

                if ($this.attr('minlength') !== undefined && $this.val().length >= parseInt($this.attr('minlength')))
                {
                    $this.parent().removeClass('error');
                }
                if ($this.attr('minlength') !== undefined && $this.val().length < parseInt($this.attr('minlength')))
                {
                    $this.parent().addClass('error');
                    $this.siblings('.error-text').text($this.data()['minLengthMessage'].replace('#len#', $this.attr('minlength')));
                    flag = false;
                }
                if ($this.attr('type') == 'tel' && $this.val().indexOf("_") != -1) {
                    $this.parent().addClass('error');
                    $this.siblings('.error-text').text('Напишите телефон полностью');
                    flag = false;
                }
                if ($this.attr('type') == 'email' && !check_mail($this.val())) { //проверить валидацию email
                    $this.parent().addClass('error');
                    $this.siblings('.error-text').text('Некорректный email');
                    flag = false;
                }
            }
        });

        if (!flag) {
            e.preventDefault();
            //скролим к input с ошибкой + отменяем событие браузера(на отправку формы)
            let inputErrorScroll = $form.find('.input-text-row.error').offset().top;
            $('html, body').animate({
                scrollTop: inputErrorScroll - 100
            }, 500);
            return;
        }
    });

    //функция проверки валидности email
    function check_mail(mail){
        mail = (mail != undefined) ? mail : '';
        var pattern = /^([a-z0-9_\.-])+[@][a-z0-9-]+\.([a-z0-9-]{1,}\.)?[a-z]{2,6}$/i;
        if (!mail.match(pattern)) return false;

        return true;
    }
});

// upload input
$(function () {
    const $uploadsBlock = $('.upload-files-block');
    const maxInputs = 9;

    $uploadsBlock.find('.upload-btn').click(function(e) {
        let $uploadBlock = $(this).closest('.upload-files-block');
        let $uploadList = $uploadBlock.find('.upload-files-row');
        let input = $uploadList.find('input[type=file]');

        //добавляем  новый инпут
        $uploadList.append('<div class="upload-file"><input type="file" name="files[]"><span class="file-name"></span></div>');

        //открываем его
        $uploadBlock.find('.upload-file:last input[type=file]').trigger('click');

        //проверяем пустые инпуты и удаляем их
        input.each(function (index, elem) {
            // console.log(index)
            if ($(elem).val() == "") {
                $(elem).parent().remove();
            }
        });
    });

    $uploadsBlock.on('change', 'input[type=file]', function(e){
        let $fileItem = $(this).parent();
        let $fileName = $(this).siblings('.file-name');
        let filename = $(this).val().replace(/.*\\/, "");
        let $mainBlock = $(this).closest('.upload-files-block');
        let $otherInputs = $mainBlock.find('input[type=file]');

        $fileName.text(filename);
        $fileItem.append('<i class="close-button"></i>');

        if ($otherInputs.length >= maxInputs) {
            $mainBlock.addClass('max-files');
        }
    });

    $uploadsBlock.on('click', '.upload-file .close-button', function(){
        let $fileItem = $(this).parent();
        let $mainBlock = $(this).closest('.upload-files-block');

        $fileItem.remove();
        $mainBlock.removeClass('max-files');
    });
})



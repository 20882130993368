CW = window.CW || {};


CW.basket.regBasketUpdateEvent('header', function (res) {
    var $headerItemCart = $('.icon-header-item.cart');
    var $headerItemFavorite = $('.icon-header-item.favorite');

    var fovarCount  = $headerItemFavorite.find('.count');
    var basketCount = $headerItemCart.find('.count');

    if(res.DELAY_PRODUCT.length > 0){
        if(!$headerItemFavorite.hasClass('active')){
           $headerItemFavorite.addClass('active');
        }
        if(fovarCount.hasClass('hide')){
           fovarCount.removeClass('hide');
           fovarCount.addClass('show');
        }
        fovarCount.text((res.DELAY_PRODUCT.length > 9) ? '9+' : res.DELAY_PRODUCT.length);
    }else{
        if($headerItemFavorite.hasClass('active')){
           $headerItemFavorite.removeClass('active');
        }
        if(fovarCount.hasClass('show')){
           fovarCount.removeClass('show');
           fovarCount.addClass('hide');
        }
    }
    if (res.COUNT > 0) {
        if (!$headerItemCart.hasClass('active')) {
            $headerItemCart.addClass('active');
        }

        if (basketCount.hasClass('hide')) {
            basketCount.removeClass('hide');
            basketCount.addClass('show');
        }
        basketCount.text((res.COUNT > 9) ? '9+' : res.COUNT);
    } else {
        if ($headerItemCart.hasClass('active')) {
            $headerItemCart.removeClass('active');
        }

        if (basketCount.hasClass('show')) {
            basketCount.removeClass('show');
            basketCount.addClass('hide');
        }
    }

    $headerItemCart.find('.header-menu-text').text(CW.price(res.SUMM, true));
});


$(function () {
    //фиксируем header
    $(window).scroll(function () {
        if ($(window).scrollTop() > 10) {
            $('header').addClass('fixed-header');
        } else {
            $('header').removeClass('fixed-header');
        }
    });

    //фиксируем header при загрузке, если скролл больше 0
    $(document).ready(function () {
        if ($(window).scrollTop() > 0) {
            $('header').addClass('fixed-header');
        } else {
            $('header').removeClass('fixed-header');
        }
    });

    //------- popup -------

    //если popup при загрузке
    $(document).ready(function () {
        if ($('.site-popup').hasClass('opened')) {
            $('body').addClass('fixed-body');
            $('.overflow-body').addClass('show-open-popup');
        }
    });

    //popup выбора города
    $('body').on('click', '.location-href', function () {
        $('.site-popup.city-popup').addClass('opened');
        $('body').addClass('fixed-body');
        $('.overflow-body').addClass('show-open-popup');
        $('.city-popup .bx-ui-sls-fake').focus();
    });


    // после отправки заявки на подписку - открытие попапа

    function openResultPopup(msg, status, email) {

        const $modal = $('.site-popup.mailing-popup');
        $modal.addClass('opened');
        $('body').addClass('fixed-body');
        $('.overflow-body').addClass('show-open-popup');

        const $contents = $modal.find('.js-content');

        $contents.each(function(i, item){
            $(item).addClass('hidden');
        })

        if(status) {
            if (!CW.getCookie("BITRIX_SM_SUBSCRIBE_FORM")) {
                var date = new Date;
                date.setDate(date.getDate() + 2);
                CW.setCookie("BITRIX_SM_SUBSCRIBE_FORM", "true", date.toUTCString(), '/');
            }
            $modal.find('.js-success-content').removeClass('hidden');
            const userEmailEem = $modal.find('.js-user-email');
            const userEmail = email ? email : '';
            userEmailEem.text(userEmail);
        } else {
            const message = msg ? msg : 'Ошибка';
            $modal.find('.js-error-content').removeClass('hidden').html(`<div class="error-message">${message}</div>`);
        }

        return false

    }


    $('#cw-subscribe').on('focus', '.js-email-input', function() {
        const $this = $(this);
        const errorMsg = $this.closest('.js-input-label').find('.js-error-msg');
        errorMsg.text('').addClass('hidden');
    })

    // валидация согласия на обработку перс данных

    function validateAgreement(checkbox) {
        const $checkbox = $(checkbox);
        const errorMsg = $(checkbox).closest('form').find('.js-agreement-error');
        let result = true

        if ($checkbox[0].checked) {
            errorMsg.text('').addClass('hidden');
        } else {
            errorMsg.text('Необходимо согласиться с обработкой персональных данных').removeClass('hidden')
            result = false
        }

        return result
    }

    // валидация email
    function validateEmail(input) {
        const LATIN_EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
        const inputValue = input.val();
        const errorElem = input.closest('.js-input-label').find('.js-error-msg');
        errorElem.text('').addClass('hidden');
        const result = LATIN_EMAIL_REGEXP.test(inputValue);

        if(!result) {
            errorElem.removeClass('hidden')
            errorElem.text('Поле E-mail необходимо заполнить');
        }

        return result
    }

    // вставка скрипта рекапчи после начала взаимодействия с формами
    $(document).on('click',
        '.subscription-form input, #review-form input, #review-form textarea, #review-form-popup  input, #review-form-popup textarea, .bxmodAuthShowLink', function (e) {
        if (typeof(grecaptcha) == 'undefined') {
            var grecaptcha_s = document.createElement('script');
            grecaptcha_s.src = 'https://www.google.com/recaptcha/api.js?render=6Lfaw70pAAAAAJbqH-qEyEKMMQ-G7NcGyoeJ_76c';
            var grecaptcha_h = document.getElementsByTagName('script')[0];
            grecaptcha_h.parentNode.insertBefore(grecaptcha_s,grecaptcha_h);
        }
    })

    // оформить подписку

    $(document).on('submit', '.subscription-form', function (e) {


        e.preventDefault();
        var $form = $(this),
            formData = $form.serializeArray();
        var emailInput = $form.find('.js-email-input');
        var checkbox = $form.find('#customCheckbox');
        var email = emailInput.val();

        grecaptcha.ready(function() {
            grecaptcha.execute('6Lfaw70pAAAAAJbqH-qEyEKMMQ-G7NcGyoeJ_76c', {action: 'submit'}).then(function(token) {
                if(token) {
                    formData.push({name: 'recaptcha', value: token});
                    formData = $.param(formData);
                    if(validateEmail(emailInput) && validateAgreement(checkbox)) {
                        $.ajax({
                            global: false,
                            type: "POST",
                            url: "/ajax/subscribe/",
                            data: formData,
                            success: function (r) {
                                // debugger
                                let msg = r.msg;

                                switch (r.result) {
                                    case 'failEmail':
                                        openResultPopup(msg, false, email);
                                        emailInput.val('');
                                        break;
                                    case 'error':
                                        openResultPopup(msg, false);
                                        emailInput.val('');
                                        break;
                                    case 'ok':
                                        ga('send', 'event', 'subscribe', 'press');
                                        openResultPopup(msg, true, email);
                                        emailInput.val('');
                                        break;

                                    default:
                                        console.log('Системная ошибка.');
                                        break;
                                }
                            },
                            error: function(error) {
                                console.log(error)
                            }
                        });
                    } else {
                        console.log('поля не прошли валидацию')
                    }
                } else {
                    openResultPopup('Не пройдена проверка капчи', false, email);
                }

            })
        })
    })

    //popup написать директору
    $('.btn-director-popup').on('click', function (e) {
        e.preventDefault();
        $('.site-popup.director-popup').addClass('opened');
        $('body').addClass('fixed-body');
        $('.overflow-body').addClass('show-open-popup');
        return false;
    });

    //popup оставить заявку
    $('.btn-application-popup').on('click', function (e) {
        e.preventDefault();
        $('.site-popup.js-application-popup').addClass('opened');
        $('body').addClass('fixed-body');
        $('.overflow-body').addClass('show-open-popup');
        return false;
    });

    //popup заказать консультацию
    $('.contacts-page .btn-complaint').on('click', function () {
        $('.site-popup.complaint-popup').addClass('opened');
        $('body').addClass('fixed-body');
        $('.overflow-body').addClass('show-open-popup');
    });

    //закрыть меню и пупапы по клику на close в пупапах
    $('.site-popup .close-button').on('click', function (e) {

        $('.overflow-body').removeClass('show-open-popup');
        $(this).closest('.site-popup').removeClass('opened');
        $('body').removeClass('fixed-body');
    });

    $('.site-popup .js-close-button').on('click', function (e) {

        $('.overflow-body').removeClass('show-open-popup');
        $(this).closest('.site-popup').removeClass('opened');
        $('body').removeClass('fixed-body');
    });


    //закрыть все пупапы по клавише esc
    $(document).on('keydown', function (event) {
        if (event.which == 27) {
            $('.overflow-body').removeClass('show-open-popup');
            $('.site-popup').removeClass('opened');
            $('body').removeClass('fixed-body');
            $('.shadow-bg').hide();
        }
    });

    //------- Поиск -------

    $('.search-form').on('submit', function (e) {
        let $this = $(this);
        let $input = $this.find('input');
        let search = encodeURI($input.val().replace('%', '%25').toLowerCase());

        if(search === '' || $input.val().replace(/\s/g, '') === '')
        {
            return false;
        }
        search = search.replace(/\//g, "_slash_");
        location.href = $this.attr('action') + search + '/';
        e.preventDefault();
        return false;
    });

    let quickSearchRequest = false;

    //show/hide для close у поиска
    $('.search-form .input-search').on('input', function () {
        let $parent = $(this).closest('.search-form ');
        let val = $(this).val();
        let $closeBtn = $parent.find('.search-clear');

        let quickSearchBlock = $('.search-form .js-quick-search');
        let quickSearchResult = quickSearchBlock.find('.items');

        if (val != '') {
            $closeBtn.show();

            if(val.length >= 3 && !quickSearchRequest)
            {

                quickSearchRequest = true;
                quickSearchBlock.hide();
                quickSearchResult.find('.item').remove();

                $.post(
                    '/local/tools/ajax/quick-search.php',
                    {
                        query: val,
                    },
                    function(response)
                    {
                        if(response['items'] !== undefined && response['items'].length > 0)
                        {

                            $.each(
                                response['items'],
                                function(i, item)
                                {

                                    if(item['query'] !== undefined && item['query'].length > 0)
                                    {
                                        quickSearchResult.append(
                                            $('<div />')
                                                .addClass('item')
                                                .append(
                                                    $('<a />')
                                                        .attr('href', item['url'])
                                                        .text(item['query'])
                                                )
                                        );
                                    }
                                }
                            );

                            if(quickSearchResult.find('.item').length > 0)
                            {
                                quickSearchBlock.show();
                            }
                        }

                        quickSearchRequest = false;
                    },
                    'json'
                )
            }
        } else {
            quickSearchBlock.hide();
            quickSearchResult.find('.item').remove();
            $closeBtn.hide();
        }
    });

    //стереть все по клику на search-clear
    $('.search-form .search-clear').on('click', function () {
        let $parent = $(this).closest('.search-form ');
        let $input = $parent.find('.input-search');

        let quickSearchBlock = $('.search-form .js-quick-search');
        let quickSearchResult = quickSearchBlock.find('.items');

        quickSearchBlock.hide();
        quickSearchResult.find('.item').remove();

        $input.val('');
        $(this).hide();
        $parent.find('.input-text-row').removeClass('input-filled');
    });

    var $directorForm = $('#director-form').submit(function (e) {
        e.preventDefault();

        var $form = $(this),
            formData = new FormData($form.get(0));

        $.ajax({
            url: '/ajax/director/',
            contentType: false,
            processData: false,
            type: "POST",
            dataType: 'json',
            data: formData,
            success: function (json) {
                if (json.success) {
                    $directorForm.find('.form-wr').html(
                        '<div style="font-size: 2em;color: #41ead4;">' + json.success + '</div>');
                } else {

                    if(json['error'] !== undefined)
                    {

                        let errorMessage  = '';
                        $.each(
                            json['error'],
                            function(key, message)
                            {
                                errorMessage += message;
                                errorMessage += '<br>';
                            }
                        )

                        $("#alert_popup").html(errorMessage);
                        $(".site-popup.alert-popup").addClass('opened');
                    }
                }
            }
        });

        return false;
    });

    $('.js-terms-of-use').click(
        function(e)
        {

            $(".site-popup.footer-terms-of-use").addClass('opened');
            $('.shadow-bg').fadeIn(300);
            e.preventDefault();
        }
    );

    $('.footer-row.menu .footer-menu-item .block-title').click(
        function()
        {
            if($(window).width() <= 575)
            {

                if($(this).hasClass('active'))
                {
                    $(this).removeClass('active');
                    $(this).parent().find('.menu-items').slideUp();
                    return;
                }

                $('.footer-row.menu .footer-menu-item .block-title').removeClass('active');
                $('.footer-contact-block .block-title').removeClass('active');
                $(this).addClass('active');

                $('.footer-row.menu .footer-menu-item .menu-items').slideUp();
                $('.footer-contact-block .display-block').slideUp();

                $(this).parent().find('.menu-items').slideDown();
            }
        }
    );

    $('.footer-contact-block .block-title').click(
        function()
        {
            if($(window).width() <= 575)
            {

                if($(this).hasClass('active'))
                {

                    $(this).removeClass('active');
                    $('.footer-contact-block .display-block').slideUp();
                    return;
                }

                $('.footer-row.menu .footer-menu-item .block-title').removeClass('active');
                $('.footer-contact-block .block-title').removeClass('active');
                $('.footer-row.menu .footer-menu-item .menu-items').slideUp();

                $(this).addClass('active');
                $(this).parent().find('.display-block').slideToggle();
            }
        }
    );
});

export default class NotePopup {
    constructor({selector}) {
        if (!selector) return

        this.wrapper = selector;
        this.eventListeners();
    }


    eventListeners() {
        this.wrapper.addEventListener('click', (e) => {
            if(e.target.closest('.js-added-note-popup-close')) {
                this.hidePopup();
            }
        })
    }

    hidePopup() {
        this.wrapper.classList.remove('open');
    }

}

$(function ()
    {
        //Добавить в избранное
        $('.index-page .add-to-favorites').on('click', function () {
            var _this = $(this);
            var productId = _this.data("product-id");
            var action = 'add';

            if(_this.hasClass('in-favorites')){
                action = 'remove';
            }
            $.ajax({
                url: '/ajax/favorites/',
                global: false,
                type: "POST",
                data: ({
                    action: action,
                    id: productId
                }),
                success: function (res) {
                    if(res.result && res.result == 'fail'){
                        console.log(res.error);
                    }else{
                        CW.basket.setBasket(res);
                    }
                }
            });
        });


    }
)
$(function () {
    //------- MENU -------
    //функция открыть субменю каталога
    var openSubMenu = function ($this) {
        let $siteMenu = $this.closest('.site-menu');
        let index = $this.index();
        let $subMenu = $siteMenu.find('.site-submenu-wr .mode-list .mode-item');
        let $listMenu = $this.closest('.site-menu-wr');

        $this.addClass('active');
        $this.siblings('.site-menu-item').removeClass('active');

        if ($subMenu.eq(index).length > 0 && !$subMenu.eq(index).hasClass('open')) {
            $subMenu.removeClass('open');
            $subMenu.eq(index).addClass('open');
            $('body').addClass('fixed-body');
            if ($(window).width() < '1023') { //меняем отображение суб меню на мобильно
                $listMenu.addClass('open-submenu');
            }

            if ($(window).width() > '1023') { //добавляем overflow на десктопе
                $('.overflow-body').addClass('show-overflow');
            }
        } else {
            if ($(window).width() > 1023) { //убираем overflow на десктопе
                $('.overflow-body').removeClass('show-overflow');
            }

            $subMenu.removeClass('open');
            $('body').removeClass('fixed-body');
        }

        $('.product-page .product-row .product-photo-wr .flair').addClass('hidden-block');
    };

    //функция закрыть субменю каталога
    var closeSubMenu = function ()
    {

        let $parent = $('.site-menu');
        let $mobileIcon = $('.mobile-menu-icon');

        $parent.removeClass('open');
        $parent.find('.site-submenu-wr .mode-list .mode-item').removeClass('open');
        $parent.find('.site-menu-item').removeClass('active');
        $parent.children('.site-menu-wr').removeClass('open-submenu');
        $mobileIcon.removeClass('active');
        $('body').removeClass('fixed-body');
        $('.overflow-body').removeClass('show-overflow');
        $parent.removeClass('desktop-menu-opened');
        $('.product-page .product-row .product-photo-wr .flair').removeClass('hidden-block');
    };


    //отключаем переход по ссылке, если есть вложенность
    // $('.site-menu .site-menu-list .site-menu-item a').on(
    //     'click',
    //     function (e)
    //     {
    //
    //         if ($(window).width() < 1023)
    //         {
    //             let $siteMenu = $(this).closest('.site-menu');
    //             let $subMenu = $siteMenu.find('.site-submenu-wr .mode-list .mode-item');
    //             let index = $(this).parent().index();
    //
    //             if ($subMenu.eq(index).length > 0) {
    //                 e.preventDefault();
    //             }
    //         }
    //     }
    // );

    //открыть субменюменю по клику
    // $('.site-menu .site-menu-list .site-menu-item').click(function (e) {
    //     if (!$(this).hasClass('active')) {
    //         openSubMenu($(this));
    //         if ($(window).width() > 1023)
    //         {
    //             //добавить класс-флаг для индикации открытия меню на дескотпах
    //             if (!$('.site-menu').hasClass('desktop-menu-opened')) {
    //                 $('.site-menu').addClass('desktop-menu-opened');
    //             }
    //         }
    //     } else {
    //         //closeSubMenu();
    //     }
    // });

    $('body .overflow-body').hover(
        function()
        {
            // closeSubMenu();
        }
    )

    // $('.site-menu .site-menu-list .site-menu-item').hover(
    //     function (e)
    //     {
    //         if (!$(this).hasClass('active'))
    //         {
    //             openSubMenu($(this));
    //             if ($(window).width() > 1023)
    //             {
    //                 //добавить класс-флаг для индикации открытия меню на дескотпах
    //                 if (!$('.site-menu').hasClass('desktop-menu-opened')) {
    //                     $('.site-menu').addClass('desktop-menu-opened');
    //                 }
    //             }
    //         }
    //     }
    // );

    //открываем субменю по ховеру, только если уже открыто меню (был клик) - только для дектопа
    // $('.site-menu .site-menu-list .site-menu-item').hover(function () {
    //     if ($('.site-menu').hasClass('desktop-menu-opened') && !$(this).hasClass('active')) {
    //         let $this = $(this);
    //         window.hoverMainMenu = setTimeout(function () {
    //             openSubMenu($this);
    //         }, 300);
    //     }
    // }, function () {
    //     clearTimeout(window.hoverMainMenu);
    //     if (!$('.site-submenu-wr .mode-list .mode-item').hasClass('open')) {
    //         $(this).removeClass('active');
    //     }
    // });

    // открываем меню с задержкой
    // $('.site-menu .site-menu-list .site-menu-item').hover(function() {
    //     let $this = $(this);
    //     window.initHandler = setTimeout(handler, 100);
    //
    //     function handler() {
    //         openSubMenu($this);
    //     }
    // }, function() {
    //     clearTimeout( window.initHandler );
    //     if (!$('.site-submenu-wr .mode-list .mode-item').hasClass('open')) {
    //         $(this).removeClass('active');
    //     }
    // });

    //открыть/закрыть меню на мобильном
    $('header .mobile-menu-icon').on('click', function () {
        let $siteMenu = $('.site-menu');
        let $listMenu = $siteMenu.find('.site-menu-wr');
        let $modeItem = $siteMenu.find('.site-submenu-wr .mode-list .mode-item');

        if ($(this).hasClass('active')) {
            $siteMenu.removeClass('open');
            $(this).removeClass('active');
            $('.product-size-block').removeClass('open');
            closeSubMenu();
            $('header').removeClass('mob-menu-open');
        } else {
            $(this).addClass('active');
            $siteMenu.addClass('open');
            $('body').addClass('fixed-body');
            $('.overflow-body').addClass('show-overflow');
            $('header').addClass('mob-menu-open');
        }
    });

    //кнопка назад на мобильном
    $('.site-menu .back-menu-link').on('click', function () {
        let $parent = $(this).closest('.site-menu');

        $parent.find('.mode-list .mode-item').removeClass('open');
        $parent.find('.site-menu-wr').removeClass('open-submenu');
        $parent.find('.site-menu-item').removeClass('active');
    });

    //закрыть меню и все popups по клику на overflow-body
    $('.overflow-body').on('click', function () {
        closeSubMenu();
        $(this).removeClass('show-open-popup');
        $('.site-popup').removeClass('opened');
    });

    //закрыть меню каталога по клику на icon-close
    $('.site-menu .close-button').on('click', function () {
        closeSubMenu();
    });

    //закырть меню по ховеру, если у раздела нет субменю - только на десктопе
    $('.site-menu').mouseleave(function (e) {
        if ($('.site-menu').hasClass('desktop-menu-opened') && !$('.site-submenu-wr .mode-list .mode-item').hasClass('open')) {
            closeSubMenu();
        }
    });

    // закрываем меню по клику на хедер - только для дектопа
    $('header').on('click', function () {
        if ($(window).width() > '1023') {
            closeSubMenu();
        }
    });

    //закрыть меню по клавише esc
    $(document).on('keydown', function (event) {
        if (event.which == 27) {
            closeSubMenu();
        }
    });

    //закрыть меню клику на close в пупапах
    $('.site-popup .close-button').on('click', function (e) {
        closeSubMenu();
    });
    if($('.index-page')[0]) {
        $('.double-header').addClass('index-page-double-header')
    }

    if($('#basket-page')[0] || $('#order-page')[0]) {
        $('header').addClass('fixed-page')
    }
})
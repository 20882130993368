document.addEventListener('click', function(e) {
    const target = e.target;

    // добавить в сравнение
    if (target.closest('.js-add-to-compare')) {
        addToCompare(target);
    }
})

//добавить/удалить в сравнение
function addToCompare(elem) {
    const _this = $(elem.closest('.js-add-to-compare'));
    const productId = _this.data("product-id");
    const data = JSON.stringify({ productId: productId });
    const compareHeaderCount = document.querySelector('.icon-header-item.compare .count');
    const mobileHeaderCount = document.querySelector('.mobile-compare-icon .count');

    if(_this.hasClass('active')){
        // DELETE
        $.ajax({
            url: '/compare/',
            global: false,
            type: "DELETE",
            data: data,
            success: (res) => {
                if(res && res.status == 'ok') {
                    _this.removeClass('active');

                    // выводим попап в хедере для страниц кроме страницы сравнения
                    showComparePopup('Товар удален из сравнения');

                    // обновляем счетчик в хедере
                    if(res.payload) {
                        if(res.payload.count > 0 && res.payload.count < 10) {
                            $(compareHeaderCount).removeClass('hide');
                            $(mobileHeaderCount).removeClass('hide');
                            compareHeaderCount.innerText = res.payload.count;
                            mobileHeaderCount.innerText = res.payload.count;
                        }
                        if (res.payload.count > 9) {
                            $(compareHeaderCount).removeClass('hide');
                            $(mobileHeaderCount).removeClass('hide');
                            compareHeaderCount.innerText = '9+';
                            mobileHeaderCount.innerText = '9+';
                        }
                        if (res.payload.count < 1) {
                            $(compareHeaderCount).addClass('hide');
                            $(mobileHeaderCount).addClass('hide');
                        }
                    }

                    // если это страница товара - удаляем класс активности у обеих иконок "в сравнение"
                    if(_this.closest('.js-product-row').length) {
                        const icons = _this.closest('.js-product-row').find('.js-add-to-compare');
                        icons.each((idx,icon) => {
                            $(icon).removeClass('active')
                        })
                    }

                } else if (res && res.status == "error") {
                    console.log(res.error)
                } else {
                    alert('При добавлении товара в сравнение произошла ошибка')
                }
            },
            error: function(err) {
                alert("Во время обработки запроса произошла ошибка");

                if(err && err.responseJSON) {
                    console.log(err.responseJSON)
                } else {
                    console.log('Error:', err)
                }

            }
        });
    } else {
        $.ajax({
            url: '/compare/',
            global: false,
            type: "PUT",
            data: data,

            success: (res) => {
                if(res && res.status == 'ok') {
                    _this.addClass('active');
                    // выводим попап в хедере для страниц кроме страницы сравнения
                    showComparePopup('Товар добавлен к сравнению');

                    // обновляем счетчик в хедере
                    if(res.payload) {
                        if(res.payload.count > 0 && res.payload.count < 10) {
                            $(compareHeaderCount).removeClass('hide');
                            $(mobileHeaderCount).removeClass('hide');
                            compareHeaderCount.innerText = res.payload.count;
                            mobileHeaderCount.innerText = res.payload.count;
                        }
                        if (res.payload.count > 9) {
                            $(compareHeaderCount).removeClass('hide');
                            $(mobileHeaderCount).removeClass('hide');
                            compareHeaderCount.innerText = '9+';
                            mobileHeaderCount.innerText = '9+';
                        }
                        if (res.payload.count < 1) {
                            $(compareHeaderCount).addClass('hide');
                            $(mobileHeaderCount).addClass('hide');
                        }
                    }

                    // если это страница товара - добавляем класс активности у обеих иконок "в сравнение"
                    if(_this.closest('.js-product-row').length) {
                        const icons = _this.closest('.js-product-row').find('.js-add-to-compare');
                        icons.each((idx,icon) => {
                            $(icon).addClass('active')
                        })
                    }
                }
                else if (res && res.status == "error") {
                    console.log(res.error)
                } else {
                    alert('При добавлении товара в сравнение произошла ошибка')
                }
            },
            error: function(err) {
                //alert("Во время обработки запроса произошла ошибка");

                if(err && err.responseJSON.error == 'USER_UNAUTHORIZED') {
                    console.log(err.responseJSON);
                } else {
                    console.log('Error:', err)
                }

            }
        });
    }
}

// показать модальное окно о добавлении/удалении

function showComparePopup(text) {
    const successPopup = $('.js-compare-popup');

    if(!window.location.href.includes('compare')) {
        $(successPopup).find('.added-note-popup__title').text(text);
        $(successPopup).addClass('open');
        setTimeout(()=> {
            $(successPopup).removeClass('open');
        }, 3000);
    }
}
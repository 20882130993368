import lottie from './libs/lottie';

$(function () {
    var svgContainer = document.getElementById('animation-container');
    var animation = lottie.loadAnimation({
        container: svgContainer,
        renderer: 'gif',
        loop: false,
        autoplay: false,
        path: '/src/img/animation/error.json'
    });

    setTimeout(function(){ animation.play(); }, 1000);
});
export default class HiddenText {
    constructor({selector}) {
        if (!selector) return

        this.wrapper = selector;
        this.btn = this.wrapper.querySelector('.js-hidden-text-content-btn');
        this.btnText = this.btn.querySelector('span');
        this.content = this.wrapper.querySelector('.js-hidden-text-content');
        this.maxLines = this.wrapper.dataset.collapsedLines || 5;

        this.eventListeners();
        this.truncateText();
    }

    eventListeners() {
        this.btn.addEventListener('click', (e)=> {
            e.preventDefault();
            if(this.content.classList.contains('collapsed')) {
                this.showMore();
            } else {
                this.truncateText();
            }
        })
    }

    showMore() {
        this.btnText.textContent = "Свернуть";
        this.btn.classList.add('open');
        this.content.classList.remove('collapsed');
        this.content.style.height = 'auto';
        this.content.style.overflow = 'unset';
        this.content.style.textOverflow = 'unset';
    }


    truncateText() {
        this.btn.classList.remove('open');
        const element = this.content;
        element.classList.add('collapsed');
        this.btnText.textContent = "Подробнее";
        const lineHeight = parseFloat(getComputedStyle(element).lineHeight) || 1.5;

        const maxHeight = lineHeight * this.maxLines;
        if (element.offsetHeight > maxHeight) {
            element.style.height = `${maxHeight}px`;
            element.style.overflow = 'collapsed';
            element.style.textOverflow = 'ellipsis';
            element.style.overflow = 'hidden';
        }
    }

}
import scrollbar from 'jquery.scrollbar';

import '../libs/svgxuse.min';
import '../libs/jquery.maskedinput';
import '../libs/jquery.suggestions.min';
import Swiper from '../libs/swiper-bundle.min';

import '../cw.js';
import '../cw.basket.js';
import '../input.js';
import '../components/addToCompare';
import '../common';
import '../layout';
import '../menu';
import '../geolocation.js';
import '../for-safari';
import '../page-error';
import '../slider'
import '../products-card'
import '../index-page'

import HiddenText from '../components/HiddenText';

window.$ = jquery;
window.CW = CW;


document.addEventListener("DOMContentLoaded", function() {


    // сворачивающийся текст. Подробнее / Свернуть
    const hiddenTextElems = document.querySelectorAll('.js-hidden-text-wrap');
    if(hiddenTextElems.length > 0) {
        hiddenTextElems.forEach(elem => {
            new HiddenText({
                selector: elem
            })
        })
    }

    /* если в блоке спецпредложений на главной более 4 карточек, то скрываем карточки после 4ой */
    const cards = document.querySelectorAll('.section-special-offers__inner .swiper-slide');
    cards.forEach((i, card)=> {
        if(i>3) {
            card.style.display = 'none'
        }
    })


})


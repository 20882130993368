CW = window.CW || {};

CW.OPT_BORDER = 25600;
CW.formOfWord = function (count, s1, s2_4, s05, permanent) {

    count = parseInt(count) % 100;
    if (count > 20) {
        count = count % 10;
    }
    if (!permanent) {
        permanent = '';
    }
    switch (true) {
        case count === 0 :
        case count >= 5:
            return permanent + s05;
        case count === 1:
            return permanent + s1;
    }
    return permanent + s2_4;

}
CW.getFromArrayByValue = function (array, key, value) {
    let result = false;
    array.forEach(function (one) {
        if (!result && (one[key] == value)) {
            result = one;
        }
    });
    return result;
}
CW.filterArrayByValue = function (array, key, value) {
    let newarray = [];
    array.forEach(function (one) {
        if (one[key] == value) {
            newarray.push(one);
        }
    });
    return newarray;
}
CW.getRandomInt = function (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
CW.setLocation = function (curLoc){
    try {
      window.history.pushState(null, null, curLoc);
      return;
    } catch(e) {}
    window.location.hash = '#' + curLoc;
}
CW.removeURLParameter = function (url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts= url.split('?');   
    if (urlparts.length>=2) {

        var prefix= encodeURIComponent(parameter)+'=';
        var pars= urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i= pars.length; i-- > 0;) {    
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
                pars.splice(i, 1);
            }
        }
        
        if(pars.length > 0) {
            url= urlparts[0]+'?'+pars.join('&');
        } else {
            url= urlparts[0];
        }

        return url;
    } else {
        return url;
    }
}
function number_format(number, decimals, dec_point, thousands_sep) {
    // Format a number with grouped thousands
    // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
    // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +     bugfix by: Michael White (http://crestidg.com)

    var i, j, kw, kd, km;

    // input sanitation & defaults

    if (isNaN(decimals = Math.abs(decimals))) {
        decimals = 2;
    }

    if (dec_point == undefined) {
        dec_point = ",";
    }

    if (thousands_sep == undefined) {
        thousands_sep = ".";
    }
    i = parseInt(number = (+number || 0).toFixed(decimals)) + "";

    if ((j = i.length) > 3) {
        j = j % 3;
    } else {
        j = 0;
    }

    km = (j ? i.substr(0, j) + thousands_sep : "");
    kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
    //kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).slice(2) : "");
    kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");

    return km + kw + kd;

}
CW.bonusPrint = function(value)
{
    var decimals = 0;

    return CW.formOfWord(
        value,
        'бонус',
        'бонуса',
        'бонусов',
        number_format(value, decimals, '.', ' ') + ' '
    );
}
CW.price = function (value,bigPrice = false) {
    var decimals = 2;
    if (value >= 100){
        decimals = 0;
    }
    return number_format(value, decimals, '.', ' ') + (bigPrice ? ' ₽' : ' р.');
};
CW.simpleSetCookie = function (name, value, minute, path) {
    if (minute) {
        var date = new Date();
        date.setTime(date.getTime() + (minute * 60 * 1000));
        var expires = date.toGMTString();
    } else
        var expires = "";
    CW.setCookie(name, value, expires, path ? path : window.location.href, "", "");

}
CW.setCookie = function (name, value, expires, path, domain, secure) {
    var cookie = name + "=" + escape(value) +
            ((expires) ? "; expires=" + expires : "") +
            ((path) ? "; path=" + path : "") +
            ((domain) ? "; domain=" + domain : "") +
            ((secure) ? "; secure" : "");
    document.cookie = cookie;
}
CW.getCookie = function getCookie(name) {
  var matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

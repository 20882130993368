// самые общие скрипты
import scrollbar from 'jquery.scrollbar';
import './libs/svgxuse.min';
import './libs/jquery.maskedinput';
import './libs/jquery.suggestions.min';

import './menu';
import './geolocation.js';
import './for-safari';
import './page-error';
// import './slider';

import Slider from "./components/Slider";
import NotePopup from './components/NotePopup';



$(function () {
    //кастомный скролл
    $('.scrollbar-inner').scrollbar();

    $(document).on('click','.pseudo-close-button',function(e){
        if(typeof e != 'undefined' && typeof e.preventDefault == 'function'){
            e.preventDefault();
        }

        $(this).parents('.site-popup').find('.close-button').click();

        return false;
    }).on('click','.close-button',function(){
        $('.shadow-bg').fadeOut(300);
    });

    //переключения табами
    $('.tabs-head .tab-btn').on('click', function (e) {
        let $this = $(this);
        let $tabs = $this.parents('.tabs-block');
        let index = $this.index();
        let $tabsContent = $tabs.find('.tab-content').eq(index);

        let event = new Event('showTab', {bubbles: true});
        $tabsContent[0].dispatchEvent(event);


        $tabs.find('li').removeClass('selected');
        $this.addClass('selected');
        $tabsContent.addClass('selected');
    });

    //переключения табами на мобильном
    $('.tab-content .mobile-tab').on('click', function () {
        let $parent = $(this).parent('.tab-content');
        let $thisContent = $parent.find('.tab-content-container');

        $parent.toggleClass('selected');
    });

    //тултип
    $('.tooltip-help-block .tooltip-btn').on(
        'click',
        function (e)
        {
           $(this).parent().find('.tooltip-content').addClass('opened');
        }
    );

    $(document).on('click', function (event)   {

            if ($('.tooltip-help-block .tooltip-content').hasClass("opened"))
            {
                if (!$(event.target).closest('.tooltip-btn, .tooltip-content').length)
                {
                    $('.tooltip-help-block .tooltip-content').removeClass("opened");
                }
            }
        }
    );

    // липкий хедер с поиском и иконками для страниц кроме корзины

    const mainHeader = $('.fixed-header-block');
    const doubleHeader = $('.double-header');
    const currentUrl = window.location.href;

    if(!currentUrl.includes("basket")) {
        toggleDoubleHeaderFixing();

        $(window).scroll(function () {
            toggleDoubleHeaderFixing();
        });
    }

    function toggleDoubleHeaderFixing() {
        const headerHeight = mainHeader.height();

        if ($(window).scrollTop() > headerHeight) {
            if(!doubleHeader.hasClass('dh-fixed')) {
                fixDoubleHeader();
            }
        } else {
            unfixDoubleHeader();
        }
    }

    function fixDoubleHeader() {
        if(doubleHeader.length) {
            doubleHeader.addClass('dh-fixed');
            mainHeader.addClass('mh-unfixed');
        }
    }

    function unfixDoubleHeader() {
        if(doubleHeader.length) {
            doubleHeader.removeClass('dh-fixed');
            mainHeader.removeClass('mh-unfixed');
        }
    }

    promocodeBtnHandler();

    // инстансы типового компонента слайдера
    const sliders = document.querySelectorAll('.js-slider')
    if (sliders.length > 0) {
        sliders.forEach(slider => {
            new Slider({
                selector: slider
            })
        })
    }

    // окно "товар добавлен в корзину" для десктопа
    const notePopupElems = document.querySelectorAll('.js-added-note-popup');

    if(notePopupElems.length > 0) {
        notePopupElems.forEach(elem => {
            new NotePopup({
                selector: elem
            })
        })
    }

    // открытие выпадашки с контактами в хедере

    const toggleButton = document.getElementById("toggleContacts");
    const contactsList = document.querySelector(".phone-section");
    const toggleArrow = document.querySelector(".icon");

    document.addEventListener("touchstart", function(event) {
        if (!event.target.closest(".phone-section") && !event.target.closest("#toggleContacts")) {
            contactsList.classList.add("contacts-list-hidden");
            toggleArrow.classList.remove('rotate-icon');
        }
    });

    if (window.innerWidth < 768) {
        toggleButton.addEventListener("click", function() {
            contactsList.classList.toggle("contacts-list-hidden");
            toggleArrow.classList.toggle('rotate-icon');
        });
    } else {

        toggleButton.addEventListener("mouseover", function() {
            contactsList.classList.remove("contacts-list-hidden");
            toggleArrow.classList.add('rotate-icon');
        });

        contactsList.addEventListener("mouseleave", function() {
            let timer = setTimeout(function() {
                contactsList.classList.add("contacts-list-hidden");
                toggleArrow.classList.remove('rotate-icon');
            }, 500);
        });
    }


});

alert = function(html_text){
    // $("#alert_popup").html(html_text.replaceAll("\r\n",'<br/>').replaceAll("\n\r",'<br/>'));
    $("#alert_popup").html(html_text.replace(/[\n\r]/g,'<br/>').replace(/[\n\r]/g,'<br/>'));
    $(".site-popup.alert-popup").addClass('opened');
    return undefined;
}

const promocodeBtnHandler = () => {
    const input = document.querySelector('.promo-cod-item input');
    const btn = document.querySelector('.promo-cod-item .btn');

    if(!!input)
    {
        input.addEventListener('input', () => {
            if (input.value.length > 0) {
                btn.classList.add('btn-active');
            } else {
                btn.classList.remove('btn-active');
            }
        })
    }
}














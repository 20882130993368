//меняем скролл для сафари при октрытии модульных окон
$(function () {
    var scrollFunc = function() {
        let currentScroll = $(window).scrollTop();
        $(window).scrollTop(currentScroll + 500);
    };
    if ($(window).width() <= 1023) {

        if ( /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            // console.log('it is safary!');
            //мобильное меню
            $('.mobile-menu-icon').click(function() {
                scrollFunc();
            });
            //мобильный фильтр
            $('.products-section .btn-mobile-filter').click(function() {
                scrollFunc();
            });
            //таблица размеров - купить
            $('.product-row .product-action .btn').click(function() {
                scrollFunc();
            });
            //таблица размеров - выбрать размер
            $('.product-row .btn-select-size').on('click', function () {
                scrollFunc();
            });
            //таблица размеров - предзаказ
            $('.product-page .tabs-content .open-table-pre-order').on('click', function (e) {
                scrollFunc();
            });
            //пупапы
            $('.btn-open-mail-popup, .btn-director-popup, .contacts-page .btn-complaint').on('click', function (e) {
                scrollFunc();
            });
        } else {
            // console.log('it is no safary, i know that!');
        }
    }
});